import _ from 'lodash'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'
import { reduceUserDomainVenueAttrs } from 'mgr/lib/utils/AppStateUtils'

const resetClientLookupNewState = (isLoading, isError) => ({
  clientLookupResults: [],
  isClientLookupLoading: isLoading,
  isClientLookupLoadError: isError,
})

const resetClientHotelLookupNewState = (isLoading, isError) => ({
  clientHotelLookupResults: [],
  isClientHotelLookupLoading: isLoading,
  isClientHotelLookupLoadError: isError,
})

const resetClientHydrateNewState = (isLoading, isError) => ({
  isClientHydrateLoading: isLoading,
  isClientHydrateLoadError: isError,
})

const toHotelLookupOptions = clientLookupHotelList => {
  if (_.isEmpty(clientLookupHotelList)) {
    return {}
  }
  // eslint-disable-next-line no-param-reassign
  return clientLookupHotelList.reduce((total, hotel) => (total[hotel.id] = hotel.name) && total, {})
}

const defaultSelectedHotelId = clientLookupHotelList => (_.isEmpty(clientLookupHotelList) ? null : clientLookupHotelList[0].id)

export const initialState = {
  searchTerms: '',
  hotelLookupOptions: {},
  selectedHotelId: null,
  clientLookupResults: [],
  isClientLookupLoading: false,
  isClientLookupLoadError: false,
  isClientHydrateLoading: false,
  isClientHydrateLoadError: false,
  clientHotelLookupResults: [],
  isClientHotelLookupLoading: false,
  isClientHotelLookupLoadError: false,
  selectedClient: null,
  clientHistory: [],
  clientTagGroupsByVenue: {},
  showMoreClientFields: false,
  belmond_client_lookup_enabled: false,
  areThirdPartyErrors: false,
}

const bookClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD: {
      const initialUserDomainVenue = _.find(Pmp.Manager.Global.UserDomainVenues.venues, { id: window.globalInit.venueId })
      const initVenue = reduceUserDomainVenueAttrs(initialUserDomainVenue)
      const { clientLookupHotelList } = initVenue
      const initHotelLookupOptions = toHotelLookupOptions(clientLookupHotelList)
      const initSelectedHotelId = defaultSelectedHotelId(clientLookupHotelList)
      const belmondClientLookupEnabled = window.globalInit.venueSettings.belmond_client_lookup_enabled
      return {
        ...state,
        initVenue,
        initHotelLookupOptions,
        initSelectedHotelId,
        belmondClientLookupEnabled,
      }
    }
    case ActionTypes.ENTER_ADD_RESERVATION: {
      const hotelLookupOptions = state.initHotelLookupOptions
      const selectedHotelId = state.initSelectedHotelId
      return { ...state, ...initialState, hotelLookupOptions, selectedHotelId }
    }
    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS: {
      // View/edit
      const { actual, venue } = action
      const { clientLookupHotelList } = venue
      const selectedClient = actual.venue_group_client
      // Map from display tags to generic tags dropdown control
      selectedClient.client_tags = selectedClient.tags_display.map(genericTagToDisplayTag)
      selectedClient.hotel_client = {
        confirmation_num: actual.hotel_confirmation,
        room_num: actual.hotel_room,
        selectedHotelId: actual.hotel_id,
        check_in_date_display: actual.hotel_check_in_date,
        check_out_date_display: actual.hotel_check_out_date,
        client_requests: actual.reservation?.client_requests,
      }
      const hotelLookupOptions = toHotelLookupOptions(clientLookupHotelList)
      const selectedHotelId = defaultSelectedHotelId(clientLookupHotelList)
      return {
        ...state,
        ...initialState,
        selectedClient,
        hotelLookupOptions,
        selectedHotelId,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      let hotelLookupOptions = state.initHotelLookupOptions
      let selectedHotelId = state.initSelectedHotelId
      const hasSelectedTimeSlot = _.isObject(action.selectedTimeSlot)
      if (hasSelectedTimeSlot) {
        const { clientLookupHotelList } = action.venue
        hotelLookupOptions = toHotelLookupOptions(clientLookupHotelList)
        selectedHotelId = defaultSelectedHotelId(clientLookupHotelList)
      }
      return { ...state, hotelLookupOptions, selectedHotelId }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_SEARCH_TERMS: {
      return {
        ...state,
        searchTerms: action.searchTerms,
      }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_HOTEL_ID: {
      return {
        ...state,
        selectedHotelId: action.selectedHotelId,
      }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_START: {
      const [isLoading, isError] = [true, false]
      return { ...state, ...resetClientHydrateNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_FAIL: {
      const [isLoading, isError] = [false, true]
      return { ...state, ...resetClientHydrateNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HYDRATED_SUCCESS: {
      const newState = {
        isClientHydrateLoading: false,
        isClientHydrateLoadError: false,
      }
      return { ...state, ...newState }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT: {
      const selectedClient = _.isObject(action.selectedClient) ? { ...action.selectedClient } : null
      if (selectedClient) {
        // Map from display tags to generic tags dropdown control
        selectedClient.client_tags = selectedClient.tags_display.map(genericTagToDisplayTag)
      }
      const clientHistory = action.clientHistory || []
      return { ...state, selectedClient, clientHistory }
    }

    /* Client Lookup */
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_START: {
      const [isLoading, isError] = [true, false]
      return { ...state, ...resetClientLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_FAIL: {
      const [isLoading, isError] = [false, true]
      return { ...state, ...resetClientLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_LOOKUP_SUCCESS: {
      const newState = {
        clientLookupResults: action.clientResults.clients,
        areThirdPartyErrors: action.clientResults.areThirdPartyErrors,
        isClientLookupLoading: false,
        isClientLookupLoadError: false,
      }
      return { ...state, ...newState }
    }

    /* Hotel Lookup */
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_START: {
      const [isLoading, isError] = [true, false]
      return { ...state, ...resetClientHotelLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_FAIL: {
      const [isLoading, isError] = [false, true]
      return { ...state, ...resetClientHotelLookupNewState(isLoading, isError) }
    }
    case ActionTypes.BOOK_CLIENT_GET_CLIENT_HOTEL_LOOKUP_SUCCESS: {
      const newState = {
        clientHotelLookupResults: action.clientResults,
        isClientHotelLookupLoading: false,
        isClientHotelLookupLoadError: false,
      }
      return { ...state, ...newState }
    }

    case ActionTypes.BOOK_CLIENT_GET_CLIENT_TAG_GROUPS_SUCCESS: {
      const { venueId, tagGroups } = action
      const newState = { ...state }
      newState.clientTagGroupsByVenue = { ...state.clientTagGroupsByVenue }
      newState.clientTagGroupsByVenue[venueId] = tagGroups
      return newState
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD: {
      const selectedClient = {
        ...state.selectedClient,
        [action.field]: action.value,
      }

      selectedClient.name_display = _.compact([selectedClient.salutation, selectedClient.first_name, selectedClient.last_name]).join(' ')

      return { ...state, selectedClient }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_CUSTOM_UNINDEXED_FIELD: {
      const selectedClient = {
        ...state.selectedClient,
        custom_unindexed: {
          ...state.selectedClient.custom_unindexed,
          [action.system_name]: action.value,
        },
      }
      return { ...state, selectedClient }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_TAGS: {
      const selectedClient = { ...state.selectedClient }
      selectedClient.client_tags = action.value
      // Map from generic tags dropdown control to display tags
      selectedClient.tags_display = action.value.map(({ tagName, tagHash, tagGroup }) => {
        const tag_name = tagName
        const tag_group_id = tagGroup.id
        const tag_color = tagGroup.color_hex
        return { tag_group_id, tag_name, tag_color }
      })
      return { ...state, selectedClient }
    }
    case ActionTypes.BOOK_CLIENT_TOGGLE_SHOW_MORE_CLIENT_FIELDS:
      return { ...state, showMoreClientFields: !state.showMoreClientFields }
    default:
      return state
  }
}

export default bookClientReducer
